import { Button, Modal } from 'antd';
import { useState } from 'react';
import { DollarOutlined } from '@ant-design/icons';
import CreateOrEditBalanceHistory from './CreateOrEditBalanceHistory';

const ModalButtonBalanceCreate = ({ closeModal, driverId, mobileNumber }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   return (
      <>
         <Button
            onClick={showModal}
            style={{ width: '100%' }}
            icon={<DollarOutlined />}>
            Пополнить/Снять
         </Button>
         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            title={`Пополнить или снять`}
            footer={null}
            width={600}
            destroyOnClose={true}>
            <CreateOrEditBalanceHistory
               id={null}
               callbackOnSuccess={handleCancel}
               driverId={driverId}
               isEditForm={false}
               mobileNumber={mobileNumber}
            />
         </Modal>
      </>
   );
};

export default ModalButtonBalanceCreate;
