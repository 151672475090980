import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const getBalanceById = (id) => get(`/buydriver/${id}`);
export const getDriverBalance = (driverId, mobileNumber) =>
   get(`/buydriver/balance/${driverId}/${mobileNumber}`);
export const getDriversBalances = (params) =>
   get(generateQueryParams(`/buydrivers`, params));

export const createDriverBalance = (fields) =>
   post(
      '/buydriver',
      {
         ...fields
      },
      true
   );

export const updateDriverBalance = (fields, id) =>
   put(
      `/buydriver/${id}`,
      {
         ...fields
      },
      true
   );
