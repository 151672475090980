import React, { useState, useEffect } from 'react';
import BalanceHistoryForm from './BalanceHistoryForm';
import { CreateBalanceHistory } from '../model/CreateBalanceHistory';
import { UpdateBalanceHistory } from '../model/UpdateBalanceHistory';
import { GetBalanceById } from '../model/GetBalanceById';
import _ from 'lodash';
import { message } from 'antd';

const CreateOrEditBalanceHistory = ({
   id = null,
   callbackOnSuccess = () => {},
   mobileNumber,
   driverId
}) => {
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   useEffect(() => {
      if (!isEditForm) {
         return;
      }
      fetchData();

      return () => {
         setErrorMessage('');
         setLoadForm(false);
      };
   }, [id]);

   const fetchData = () => {
      setLoadForm(false);
      GetBalanceById(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });
   };

   const onSuccess = async (values, setLoading) => {
      setLoading(true);
      values = {
         ...values,
         driverId: driverId,
         mobileNumber: mobileNumber
      };

      if (isEditForm) {
         const noteId = initialValues.id;

         const mergedObj = { ...initialValues, ...values };
         const updatedFields = _.pickBy(
            mergedObj,
            (v, k) => !_.isEqual(initialValues[k], v)
         );

         await UpdateBalanceHistory(updatedFields, noteId)
            .then(() => {
               callbackOnSuccess();
               message.success('Баланс изменился');
            })
            .catch((e) => {
               fetchData();
               const errMessage = e?.message || 'Возникла ошибка при сохранении';
               setErrorMessage(errMessage);
               message.error(errMessage);
            })
            .finally(() => setLoading(false));

         return;
      }

      await CreateBalanceHistory(values)
         .then(() => {
            callbackOnSuccess();
            message.success('Создано');
         })
         .catch((e) => {
            const errMessage = e?.message || 'возникла ошибка';
            setErrorMessage(errMessage);
            message.error(errMessage);
         })
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <BalanceHistoryForm
         errorMessage={errorMessage}
         initialValues={initialValues}
         isEditForm={isEditForm}
         onSuccess={onSuccess}
      />
   );
};

export default CreateOrEditBalanceHistory;
