import { Drawer, Button, Form, Row, Col, Space, Alert } from 'antd';
import VerticalSpace from '@shared/ui/VerticalSpace';
import React, { useState } from 'react';
import { Input } from '@shared/ui';
import { Radio, message } from 'antd';
import { sendWhatsapp } from '@shared/api/all/whatsapp';

const { TextArea } = Input;

function processTextarea(input) {
   if (!input) return [];
   return Array.from(
      new Set(
         input
            .split('\n') // Разбиваем текст на строки
            .map((line) => line.trim().replace(/\D+/g, '')) // Убираем все символы, кроме цифр
            .filter((line) => line) // Убираем пустые строки
      )
   );
}

const ModalFormSendMailingWhatsappWithListNumbers = () => {
   const [form] = Form.useForm();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   const onFinish = async (values) => {
      if (values.type === 'messageType' && values.message.length < 1) {
         setErrorMessage('Вы не ввели сообщение');
         return;
      }

      if (values.type === 'patternType' && values.patternMessage.length < 1) {
         setErrorMessage('Вы не ввели сообщение');
         return;
      }

      values.listOfNumbers = processTextarea(values.listOfNumbers).join(',');

      setIsLoading(true);

      await sendWhatsapp({
         mobileNumbers: values.listOfNumbers,
         message: values.message,
         messageType: values.type
      })
         .then(() => {
            message.success('Отправлено успешно!');
            setIsLoading(false);
            setTimeout(() => {
               form.resetFields();
               setIsModalOpen(false);
               setErrorMessage('');
            }, 500);
         })
         .catch((e) => setErrorMessage(e.message))
         .finally(() => setIsLoading(false));
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const modifiedInitialValues = {
      type: 'custom'
   };

   const handleOk = () => {
      setIsModalOpen(false);
      setErrorMessage('');
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      setErrorMessage('');
      form.resetFields();
   };

   return (
      <>
         <Button onClick={() => setIsModalOpen(true)}>
            Сделать рассылку по Whatpsapp по списку номеров телефонов
         </Button>
         <Drawer
            title={`Создать сообщение в ватсап`}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            footer={null}
            width={640}
            destroyOnClose={true}
            extra={<Button onClick={handleCancel}>Закрыть</Button>}>
            <Form
               style={{
                  maxWidth: '100%',
                  minWidth: 320
               }}
               form={form}
               initialValues={modifiedInitialValues}
               onFinish={onFinish}
               onFinishFailed={onFinishFailed}
               onValuesChange={() => {
                  setErrorMessage('');
               }}
               hideRequiredMark
               layout="vertical">
               {() => (
                  <>
                     <Row gutter={16}>
                        <Col span={24}>
                           <Form.Item
                              label="Выберите тип сообщения"
                              name="type" // Впринципе это и значит какое приложение
                              defaultValue="custom">
                              <Radio.Group
                                 defaultValue="custom"
                                 onChange={(e) => {
                                    form.setFieldValue('type', e.target.value);
                                 }}>
                                 <Radio.Button value="custom" defaultChecked>
                                    Сообщение
                                 </Radio.Button>
                                 <Radio.Button value="template">Шаблон</Radio.Button>
                              </Radio.Group>
                           </Form.Item>

                           {form.getFieldValue('type') === 'template' ? (
                              <Form.Item
                                 name="message"
                                 label="Шаблон"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Поле не может быть пустым'
                                    },
                                    {
                                       max: 60,
                                       message: 'Максимум 60 символов'
                                    }
                                 ]}>
                                 <Input
                                    style={{
                                       width: '100%'
                                    }}
                                    placeholder="Введите шаблон"
                                 />
                              </Form.Item>
                           ) : (
                              <Form.Item
                                 name="message"
                                 label="Содержание сообщения"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Поле не может быть пустым'
                                    }
                                 ]}>
                                 <TextArea
                                    rows={4}
                                    placeholder="Максимум 1200 символов"
                                    maxLength={1200}
                                 />
                              </Form.Item>
                           )}
                        </Col>
                     </Row>
                     <Row gutter={16}>
                        <Col span={24}>
                           <Form.Item
                              name="listOfNumbers"
                              label="Список номеров телефонов (Без +7)"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Поле не может быть пустым'
                                 }
                              ]}>
                              <TextArea
                                 rows={10}
                                 maxLength={100000}
                                 placeholder="Максимум 100000 символов"
                              />
                           </Form.Item>
                        </Col>
                     </Row>

                     {errorMessage && (
                        <Col
                           className="gutter-row"
                           span={24}
                           className="stats-card-count-col">
                           <Alert
                              message={errorMessage.replace('Error: ', '')}
                              type="error"
                              showIcon
                           />
                           <VerticalSpace />
                        </Col>
                     )}

                     <Row gutter={[16, 24]}>
                        <Col
                           className="gutter-row"
                           span={24}
                           className="stats-card-count-col">
                           <Form.Item>
                              <Button
                                 type="primary"
                                 htmlType="submit"
                                 loading={isLoading}>
                                 Отправить сообщения
                              </Button>
                           </Form.Item>
                        </Col>
                     </Row>
                  </>
               )}
            </Form>
         </Drawer>
      </>
   );
};

export default ModalFormSendMailingWhatsappWithListNumbers;
