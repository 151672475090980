import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import CreateOrEditBalanceHistory from './CreateOrEditBalanceHistory';

const ModalBalanceForm = ({ selectedData, closeModal }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      setIsModalOpen(!!selectedData?.id);
   }, [selectedData]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const isHaveData = !!selectedData?.id;

   return (
      <Modal
         title={`Редактировать баланс ${selectedData?.id}`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         onClose={handleCancel}
         footer={null}
         width={600}
         destroyOnClose={true}>
         {isHaveData && (
            <CreateOrEditBalanceHistory
               id={selectedData.id}
               callbackOnSuccess={closeModal}
               isEditForm={true}
            />
         )}
      </Modal>
   );
};

export default ModalBalanceForm;
