import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Tooltip, Spin, Dropdown } from 'antd';
import { Button, DateWithAgo } from '@shared/ui';
import { GetDriversBalances } from '../model/GetDriversBalances';
import {
   EditOutlined,
   StarFilled,
   DashOutlined,
   ExportOutlined,
   UnorderedListOutlined,
   DollarOutlined,
   BarsOutlined,
   StarOutlined
} from '@ant-design/icons';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import ModalBalanceForm from './ModalBalanceForm';
import { Typography } from 'antd';
import { VerticalSpace, StatusTag } from '@shared/ui';
import ModalButtonBalanceCreate from './ModalButtonBalanceCreate';

import SettingIcon from '@shared/assets/icons/SettingIcon';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange
} from '@shared/ui/Table';
import { formatPhoneNumber } from '@shared/utils/entity';

import { timestampToNormalDDMMYY_HHMM } from '@shared/utils/tsToTime';
import { useNavigate } from 'react-router-dom';
import { isHaveUrlListSettings, parseUrlListSettings } from '@shared/utils/browser';
import { getApiQueryParamsForTables } from '@shared/lib/table';
import { changeUrl } from '@shared/utils/browser';

const { Text } = Typography;

const initialSorter = {
   sort: 'id',
   order: 'desc'
};

const TableDriverBalancesHistory = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedData, setSelectedData] = useState(null);
   const navigate = useNavigate();

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: initialSorter
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      if (isHaveUrlListSettings) {
         const params = parseUrlListSettings();

         fetchData(params);
      }
   }, []);

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         sortOrder:
            paramsTable?.sorter?.sort === 'id'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         filtered: !!filters?.['id'],
         width: '120px',
         filteredValue: filters?.['id'] || null,
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         }),
         render: (_, record) => _
      },

      {
         title: 'Дата',
         dataIndex: 'createdAt',
         key: 'createdAt',
         sorter: true,
         width: '160px',
         sortOrder:
            paramsTable?.sorter?.sort === 'createdAt'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         render: (_) => <DateWithAgo date={_} showDate={true} />
      },
      {
         title: 'Изменение',
         dataIndex: 'change',
         width: '140px',
         key: 'change',
         render: (_) =>
            _ < 0 ? <Text type="danger">{_}</Text> : <Text type="success">+{_}</Text>
      },
      {
         title: 'Водитель',
         dataIndex: 'driverId',
         key: 'driverId',
         filtered: !!filters?.['driverId'],
         ...getColumnSearchProps({
            dataIndex: 'driverId',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         }),
         render: (_, record) => (
            <Tooltip placement="top" title={'Открыть водителя'}>
               <span
                  className="green-span-url"
                  style={{ padding: '5px 0', whiteSpace: 'nowrap' }}
                  onClick={() => navigate(`/drivers/${_}`)}>
                  Водитель №{`${_}`}
               </span>
            </Tooltip>
         )
      },
      {
         title: 'Номер телефона',
         dataIndex: 'mobileNumber',
         key: 'mobileNumber',
         filtered: !!filters?.['mobileNumber'],
         ...getColumnSearchProps({
            dataIndex: 'mobileNumber',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         }),
         render: (_) => formatPhoneNumber(_)
      },
      {
         title: 'Причина',
         dataIndex: 'note',
         key: 'note',
         render: (_) => _
      }
   ];

   const actions = [
      {
         title: <SettingIcon />,
         key: 'action',
         align: 'center',
         width: '70px',
         fixed: 'right',
         render: (_, record) => (
            <Dropdown
               menu={{
                  items: [
                     {
                        icon: <EditOutlined />,
                        label: 'Редактировать',
                        onClick: () => setSelectedData(record)
                     }
                  ]
               }}
               placement="bottomRight"
               trigger={['click']}>
               <Button icon={<DashOutlined rotate={90} />} type="text" />
            </Dropdown>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const closeModal = () => {
      setSelectedData(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);

      const queryObj = getApiQueryParamsForTables(
         params,
         paramsTable,
         initialSorter
      );
      const { page: current, limit: pageSize } = queryObj;

      GetDriversBalances(queryObj).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, current, pageSize, total: res.count }
         });

         changeUrl(params);
         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   const closeCreate = () => {
      setSelectedData(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   return (
      <>
         <ModalBalanceForm selectedData={selectedData} closeModal={closeModal} />
         <VerticalSpace />
         <div className="table-block">
            <Table
               rowKey="id"
               columns={[...columns, ...actions]}
               dataSource={data}
               pagination={false}
               onChange={handleTableChange}
               loading={isLoading}
               scroll={{ x: 1500 }}
            />
         </div>

         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableDriverBalancesHistory;
