import { useState, useEffect } from 'react';
import { getCountOfService } from '@shared/api/all/stats';
import { Tag, Card, Space, Typography, Row, Col } from 'antd';
import {
   statusesOfLeads,
   statusesOfSellers,
   statusesOfDrivers,
   statusesOfRoutes
} from '@shared/const/statuses';
import { useNavigate } from 'react-router-dom';

import { StatusTag } from '@shared/ui';
const { Text } = Typography;

const types = {
   lead: statusesOfLeads,
   seller: statusesOfSellers,
   route: statusesOfRoutes,
   driver: statusesOfDrivers
};

const MAX_IN_ONE_COL = 6;

const ServiceCountCard = (props) => {
   const { serviceName, serviceTitle, icon, urlMain } = props;
   const [statuses, setStatuses] = useState([]);
   const navigate = useNavigate();
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      getAllStatuses();
   }, []);

   const getAllStatuses = () => {
      if (!types[serviceName]) {
         return;
      }

      const statusesArr = Object.values(types[serviceName]);
      if (statusesArr) {
         statusesArr.map((status) => {
            if (status.showInMain) {
               fetchData(status);
            }
         });
      }
   };

   const fetchData = (status) => {
      setIsLoading(true);
      getCountOfService(serviceName, { status: status.value })
         .then((res) => {
            setStatuses((e) => [
               ...e,
               {
                  label: status.label,
                  value: status.value,
                  count: res?.json?.data || 0
               }
            ]);
            setIsLoading(false);
         })
         .catch(() => setIsLoading(false));
   };

   const summOfAllStauses = statuses.reduce((acc, cur) => acc + cur.count, 0);
   let statusArr = statuses;
   let secondArr = [];

   if (statuses.length > MAX_IN_ONE_COL) {
      statusArr = statuses.slice(0, MAX_IN_ONE_COL);
      secondArr = statuses.slice(MAX_IN_ONE_COL);
   }

   const isStatusesMAX = statuses.length > MAX_IN_ONE_COL;

   return (
      <Card
         bordered={false}
         loading={isLoading}
         style={{ minHeight: '280px' }}
         title={
            <Space size="small">
               {icon} {serviceTitle}
               <Text
                  type="secondary"
                  style={{ fontSize: '12px', marginLeft: '5px' }}>
                  Всего: {summOfAllStauses}
               </Text>
            </Space>
         }>
         <Row gutter={[16, 24]}>
            <Col span={isStatusesMAX ? 12 : 24}>
               {statusArr.map((element, i) => (
                  <div key={`stats-${element?.value}`}>
                     <Space
                        direction="horizontal"
                        size="small"
                        style={{ marginBottom: '5px' }}
                        onClick={() =>
                           navigate(
                              `${urlMain}?current=1&pageSize=10&status=${element?.value}`
                           )
                        }>
                        <Tag style={{ minWidth: '40px', textAlign: 'center' }}>
                           {element?.count}
                        </Tag>
                        <StatusTag status={element?.value} type={serviceName} />
                     </Space>
                  </div>
               ))}{' '}
            </Col>
            {isStatusesMAX && (
               <Col span={12}>
                  {secondArr.map((element, i) => (
                     <div key={`stats-${element?.value}`}>
                        <Space
                           direction="horizontal"
                           size="small"
                           style={{ marginBottom: '5px' }}
                           onClick={() =>
                              navigate(
                                 `${urlMain}?current=1&pageSize=10&status=${element?.value}`
                              )
                           }>
                           <Tag style={{ minWidth: '40px', textAlign: 'center' }}>
                              {element?.count}
                           </Tag>
                           <StatusTag status={element?.value} type={serviceName} />
                        </Space>
                     </div>
                  ))}{' '}
               </Col>
            )}
         </Row>
      </Card>
   );
};

export default ServiceCountCard;
