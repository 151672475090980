import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const sendWhatsapp = (fields) =>
   post(
      '/whatsapp/send',
      {
         ...fields
      },
      true
   );
