/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import { Col, Row, Alert, DatePicker, message } from 'antd';

import SelectDriver from '@widgets/FormItems/SelectDriver';
import { isHaveUrlListSettings, parseUrlListSettings } from '@shared/utils/browser';
import { getApiQueryParamsForTables } from '@shared/lib/table';
import { changeUrl } from '@shared/utils/browser';

import dayjs from 'dayjs';

const { TextArea } = Input;

const disabledDateTime = () => ({
   disabledHours: () => [0, 1, 2, 3, 4, 5]
});

const BalanceHistoryForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isDisabledButton, setIsDisabledButton] = useState(true);
   const [selectedDriver, setSelectedDriver] = useState({});
   const { initialValues, onSuccess, isEditForm, errorMessage } = props;
   const [form] = Form.useForm();

   const onFinish = (values) => {
      if (!isEditForm) {
         // if (!selectedDriver?.id || !selectedDriver?.mobileNumber) {
         //    message.error('Выберите водителя');
         //    return;
         // }
         // values.driverId = selectedDriver.id;
         // values.mobileNumber = selectedDriver.mobileNumber;
      }

      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const modifiedInitialValues = {
      ...initialValues
   };

   return (
      <Form
         name="basic"
         style={{
            minWidth: 320
         }}
         form={form}
         onFinish={onFinish}
         initialValues={modifiedInitialValues}
         onFinishFailed={onFinishFailed}
         onValuesChange={() => setIsDisabledButton(false)}
         hideRequiredMark
         layout="vertical">
         <Row gutter={24}>
            {' '}
            <Col span={24}>
               {/* <Form.Item
                  name="driverId"
                  label="Водитель"
                  rules={[
                     //   {
                     //      required: isChoosedInWork,
                     //      message: 'Выберите водителя'
                     //   },
                     {
                        validator: () => {
                           // if (
                           //    form.getFieldValue('status') === 'active' &&
                           //    form.getFieldValue('driverId')
                           // ) {
                           //    return Promise.reject(
                           //       'Нужно выбрать статус В работе'
                           //    );
                           // }

                           if (!form.getFieldValue('driverId')) {
                              return Promise.reject('Укажите водителя');
                           }

                           return Promise.resolve();

                           // if (form.getFieldValue("driverId")) {
                           //    return Promise.resolve();
                           // } else {
                           //    return Promise.reject("Some message here");
                           // }
                        }
                     }
                  ]}>
                  <SelectDriver
                     disabled={isEditForm ? true : false}
                     driver={initialValues?.driver || {}}
                     driverId={
                        form.getFieldValue('driverId') || initialValues?.driverId
                     }
                     setField={(value) => {
                        form.setFieldValue('driverId', value);
                     }}
                     setDriver={(driver) => setSelectedDriver(driver)}
                  />
               </Form.Item> */}
            </Col>
            <Col span={24}>
               <Form.Item
                  name="change"
                  label="Изменение"
                  rules={[
                     {
                        required: true,
                        message: 'Укажите изменение'
                     }
                  ]}>
                  <Input
                     style={{
                        width: '100%'
                     }}
                     placeholder="Введите сумму (отрицательное число для снятие)"
                     disabled={isEditForm ? true : false}
                     type="number"
                  />
               </Form.Item>{' '}
            </Col>
            <Col span={24}>
               <Form.Item
                  name="note"
                  label="Комментарий"
                  rules={[
                     {
                        required: true,
                        message: 'Поле не может быть пустым '
                     }
                  ]}>
                  <TextArea
                     rows={3}
                     placeholder="Максимум 300 символов"
                     maxLength={300}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={[16, 24]}>
            {errorMessage && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message={errorMessage.replace('Error: ', '')}
                     type="error"
                     showIcon
                  />
               </Col>
            )}

            <Col className="gutter-row" span={24} className="stats-card-count-col">
               <Form.Item>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={isLoading}
                     disabled={isDisabledButton}>
                     {isEditForm ? 'Сохранить' : 'Создать'}
                  </Button>
               </Form.Item>
            </Col>
         </Row>
      </Form>
   );
};

export default BalanceHistoryForm;
