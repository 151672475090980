import React from 'react';

import { Title } from '@shared/ui';
import ModalFormSendMailingWhatsappWithListNumbers from './ModalFormSendMailingWhatsappWithListNumbers';
import CanDo from '@shared/lib/CanDo';

const MailingPage = () => {
   return (
      <div>
         <Title>Рассылка</Title>
         <CanDo permission="send_custom_push_notification">
            <ModalFormSendMailingWhatsappWithListNumbers />
         </CanDo>
      </div>
   );
};

export default MailingPage;
