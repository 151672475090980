import { AuthPage, LogoutPage } from '@pages/auth';
import NotFoundPage from '@pages/notfound';
import NotPermissionPage from '@pages/notpermission';

import WelcomePage from '@pages/main';
import DriversPage from '@pages/drivers';
import SellersPage from '@pages/sellers';
import MaterialCategoriesPage from '@pages/materialCategories';

import UsersPage from '@pages/users';
import EntityPage from '@pages/entity';

import LeadsPage from '@pages/leads';
import FaqsPage from '@pages/faqs';
import LogsPage from '@pages/logs';

import ContentPage from '@pages/content';

import RatingsPage from '@pages/ratings';
import SupportPage from '@pages/support';
import DriverBalancesHistoryPage from '@pages/driverBalancesHistory';
import CreateLeadPublicPage from '@pages/public/CreateLeadPublic';

// import RoutesPage from '@pages/routes';
import AcceptancesPage from '@pages/acceptances';
// import AuctionsPage from '@pages/auctions';
import MobileAppsPage from '@pages/mobile-apps';
import MailingPage from '@pages/mailing';
import MobileAppsNumbersPage from '@pages/mobile-apps-numbers';

import ProfilePage from '@pages/profile';

import { Navigate } from 'react-router-dom';

const AppRoutes = {
   MAIN: 'main',
   AUTH: 'auth',
   LOGOUT: 'logout',
   NOT_FOUND: 'not_found',
   DASHBOARD: 'dashboard',
   USERS: 'users',
   DRIVERS: 'drivers',
   DRIVER: 'driver',
   SELLERS: 'sellers',
   SELLER: 'seller',
   LEADS: 'leads',
   LEAD: 'lead',
   // ROUTES: 'routes',
   // ROUTE: 'route',
   ACCEPTANCES: 'acceptances',
   ACCEPTANCE: 'acceptance',
   // AUCTIONS: 'auctions',
   // AUCTION: 'auction',
   MATERIAL_CATEGORIES: 'materialcategories',
   FAQ_DRIVER_APP: 'faqdriverapp',
   FAQ_SELLER_APP: 'faqsellerapp',
   PROFILE: 'profile',
   RATINGS: 'ratings',
   LOGS: 'logs',
   SUPPORT: 'support',
   CONTENT: 'content',
   MAILING: 'mailing',
   NOT_PERMISSION: 'not_permission',
   DRIVER_BALANCE_HISTORY: 'driver_balance_history',
   MOBILE_APPS: 'mobile-apps',
   MOBILE_APPS_NUMBERS: 'mobile-apps-numbers',
   PUBLIC_LEAD_CREATE: 'create-lead-public'
};

export const AppLayout = {
   default: 'default',
   dashboard: 'dashboard',
   centered: 'centered'
};

export const RoutePath = {
   [AppRoutes.MAIN]: '/',
   [AppRoutes.AUTH]: '/auth',
   [AppRoutes.PUBLIC_LEAD_CREATE]: '/public/lead/create',

   [AppRoutes.PROFILE]: '/profile',
   [AppRoutes.LOGOUT]: '/logout',
   [AppRoutes.NOT_FOUND]: '/404',
   [AppRoutes.NOT_PERMISSION]: '/401',
   [AppRoutes.DASHBOARD]: '/dashboard',
   [AppRoutes.USERS]: '/users',
   [AppRoutes.DRIVERS]: '/drivers',
   [AppRoutes.DRIVER]: '/drivers/:id',
   [AppRoutes.SELLERS]: '/sellers',
   [AppRoutes.SELLER]: '/sellers/:id',
   [AppRoutes.RATINGS]: '/ratings',
   [AppRoutes.MAILING]: '/mailing',

   [AppRoutes.LEADS]: '/leads',
   [AppRoutes.LEAD]: '/leads/:id',
   [AppRoutes.DRIVER_BALANCE_HISTORY]: '/drivers-balance-history',
   // [AppRoutes.ROUTES]: '/routes',
   // [AppRoutes.ROUTE]: '/routes/:id',
   [AppRoutes.SUPPORT]: '/support',
   [AppRoutes.ACCEPTANCES]: '/acceptances',
   [AppRoutes.ACCEPTANCE]: '/acceptances/:id',
   // [AppRoutes.AUCTIONS]: '/auctions',
   // [AppRoutes.AUCTION]: '/auctions/:id',
   [AppRoutes.LOGS]: '/logs',
   [AppRoutes.CONTENT]: '/content',
   [AppRoutes.MOBILE_APPS]: '/apps/mobile',
   [AppRoutes.MOBILE_APPS_NUMBERS]: '/apps/mobile/numbers',
   [AppRoutes.MATERIAL_CATEGORIES]: '/materialcategories',
   [AppRoutes.FAQ_DRIVER_APP]: '/content/faq/driverapp',
   [AppRoutes.FAQ_SELLER_APP]: '/content/faq/sellerapp'
};

export const routeList = {
   [AppRoutes.MAIN]: {
      path: RoutePath[AppRoutes.MAIN],
      element: <Navigate to={RoutePath.dashboard} />
   },

   [AppRoutes.PUBLIC_LEAD_CREATE]: {
      path: RoutePath[AppRoutes.PUBLIC_LEAD_CREATE],
      element: <CreateLeadPublicPage />,
      layout: AppLayout.default
   },
   [AppRoutes.DASHBOARD]: {
      path: RoutePath[AppRoutes.DASHBOARD],
      element: <WelcomePage />,
      layout: AppLayout.dashboard,
      authOnly: true
   },

   [AppRoutes.DRIVER_BALANCE_HISTORY]: {
      path: RoutePath[AppRoutes.DRIVER_BALANCE_HISTORY],
      element: <DriverBalancesHistoryPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_drivers'
   },

   [AppRoutes.MATERIAL_CATEGORIES]: {
      path: RoutePath[AppRoutes.MATERIAL_CATEGORIES],
      element: <MaterialCategoriesPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_categories'
   },
   [AppRoutes.SELLERS]: {
      path: RoutePath[AppRoutes.SELLERS],
      element: <SellersPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_sellers'
   },
   [AppRoutes.SELLER]: {
      path: RoutePath[AppRoutes.SELLER],
      element: <EntityPage type="sellers" />,
      layout: AppLayout.dashboard,
      authOnly: true,
      exact: true,
      permission: 'can_view_sellers'
   },
   [AppRoutes.RATINGS]: {
      path: RoutePath[AppRoutes.RATINGS],
      element: <RatingsPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_ratings'
   },

   [AppRoutes.SUPPORT]: {
      path: RoutePath[AppRoutes.SUPPORT],
      element: <SupportPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_support'
   },

   [AppRoutes.DRIVERS]: {
      path: RoutePath[AppRoutes.DRIVERS],
      element: <DriversPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_drivers'
   },

   [AppRoutes.MAILING]: {
      path: RoutePath[AppRoutes.MAILING],
      element: <MailingPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_support'
   },

   [AppRoutes.DRIVER]: {
      path: RoutePath[AppRoutes.DRIVER],
      element: <EntityPage type="drivers" />,
      layout: AppLayout.dashboard,
      authOnly: true,
      exact: true,
      permission: 'can_view_drivers'
   },
   // [AppRoutes.ROUTES]: {
   //    path: RoutePath[AppRoutes.ROUTES],
   //    element: <RoutesPage />,
   //    layout: AppLayout.dashboard,
   //    authOnly: true,
   //    permission: 'can_view_routes'
   // },
   // [AppRoutes.ROUTE]: {
   //    path: RoutePath[AppRoutes.ROUTE],
   //    element: <EntityPage type="routes" />,
   //    layout: AppLayout.dashboard,
   //    authOnly: true,
   //    exact: true,
   //    permission: 'can_view_routes'
   // },
   [AppRoutes.ACCEPTANCES]: {
      path: RoutePath[AppRoutes.ACCEPTANCES],
      element: <AcceptancesPage />,
      layout: AppLayout.dashboard,
      permission: 'can_view_acceptances',
      authOnly: true
   },
   [AppRoutes.ACCEPTANCE]: {
      path: RoutePath[AppRoutes.ACCEPTANCE],
      element: <EntityPage type="acceptances" />,
      layout: AppLayout.dashboard,
      authOnly: true,
      exact: true,
      permission: 'can_view_acceptances'
   },
   // [AppRoutes.AUCTIONS]: {
   //    path: RoutePath[AppRoutes.AUCTIONS],
   //    element: <AuctionsPage />,
   //    layout: AppLayout.dashboard,
   //    permission: 'can_view_auctions',
   //    authOnly: true
   // },
   [AppRoutes.MOBILE_APPS]: {
      path: RoutePath[AppRoutes.MOBILE_APPS],
      element: <MobileAppsPage />,
      layout: AppLayout.dashboard,
      permission: 'can_view_mobileNumbers',
      authOnly: true
   },
   [AppRoutes.MOBILE_APPS_NUMBERS]: {
      path: RoutePath[AppRoutes.MOBILE_APPS_NUMBERS],
      element: <MobileAppsNumbersPage />,
      layout: AppLayout.dashboard,
      permission: 'can_view_mobileNumbers',
      authOnly: true
   },

   // [AppRoutes.AUCTION]: {
   //    path: RoutePath[AppRoutes.AUCTION],
   //    element: <EntityPage type="auctions" />,
   //    layout: AppLayout.dashboard,
   //    authOnly: true,
   //    exact: true,
   //    permission: 'can_view_auctions'
   // },
   [AppRoutes.USERS]: {
      path: RoutePath[AppRoutes.USERS],
      element: <UsersPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_users'
   },
   [AppRoutes.LEADS]: {
      path: RoutePath[AppRoutes.LEADS],
      element: <LeadsPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_leads'
   },
   [AppRoutes.LEAD]: {
      path: RoutePath[AppRoutes.LEAD],
      element: <EntityPage type="leads" />,
      layout: AppLayout.dashboard,
      authOnly: true,
      exact: true,
      permission: 'can_view_leads'
   },
   [AppRoutes.CONTENT]: {
      path: RoutePath[AppRoutes.CONTENT],
      element: <ContentPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      exact: true
   },
   [AppRoutes.PROFILE]: {
      path: RoutePath[AppRoutes.PROFILE],
      element: <ProfilePage />,
      layout: AppLayout.dashboard,
      authOnly: true
   },
   [AppRoutes.FAQ_DRIVER_APP]: {
      path: RoutePath[AppRoutes.FAQ_DRIVER_APP],
      element: <FaqsPage service="driverApp" />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_faqs'
   },
   [AppRoutes.FAQ_SELLER_APP]: {
      path: RoutePath[AppRoutes.FAQ_SELLER_APP],
      element: <FaqsPage service="sellerApp" />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_faqs'
   },
   [AppRoutes.LOGS]: {
      path: RoutePath[AppRoutes.LOGS],
      element: <LogsPage />,
      layout: AppLayout.dashboard,
      authOnly: true,
      permission: 'can_view_userlogs'
   },
   [AppRoutes.AUTH]: {
      path: RoutePath[AppRoutes.AUTH],
      element: <AuthPage />,
      layout: AppLayout.centered
   },
   [AppRoutes.LOGOUT]: {
      path: RoutePath[AppRoutes.LOGOUT],
      element: <LogoutPage />,
      layout: AppLayout.dashboard,
      authOnly: true
   },
   [AppRoutes.NOT_FOUND]: {
      path: RoutePath[AppRoutes.NOT_FOUND],
      element: <NotFoundPage />,
      layout: AppLayout.default
   },
   [AppRoutes.NOT_PERMISSION]: {
      path: RoutePath[AppRoutes.NOT_PERMISSION],
      element: <NotPermissionPage />,
      layout: AppLayout.dashboard
   }
};
