import React from 'react';
import TableDriverBalancesHistory from '@features/list-balance-history/ui/TableDriverBalancesHistory';
import { Title } from '@shared/ui';

const DriverBalancesHistoryPage = () => {
   return (
      <div>
         <Title>Общий список изменения балансов всех водителей</Title>
         <TableDriverBalancesHistory />
      </div>
   );
};

export default DriverBalancesHistoryPage;
