import { updateDriverBalance } from '@shared/api/all/balanceDriver';

export const UpdateBalanceHistory = async (fields, id) => {
   try {
      const response = await updateDriverBalance(fields, id);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};
