import { Select, message, Badge } from 'antd';
import { useState, useEffect } from 'react';
import { GetDriversListSelect } from '@features/list-driver/model/GetDriversListSelect';
import { getDriverById } from '@shared/api/all/driver';

import { statusesOfDrivers } from '@shared/const/statuses';

const generateSelectLabelString = (driver) => {
   return `${driver?.firstName} ${driver?.lastName} ${driver?.mobileNumber}`;
};

const { Option } = Select;

const SelectDriver = ({
   driver,
   setField,
   driverId,
   disabled = false,
   setDriver = () => {}
}) => {
   const [drivers, setDrivers] = useState([]);
   const [searchParams, setSearchParams] = useState({});
   const [selectedDriverId, setSelectedDriverId] = useState(null);

   useEffect(() => {
      fetchDrivers(searchParams);
   }, [searchParams]);

   useEffect(() => {
      if (driverId) {
         setSelectedDriverId(driverId);
      }

      if (driverId && !driver?.id) {
         fetchOneDriver(driverId);
      }
   }, [driverId]);

   useEffect(() => {}, [drivers]);

   useEffect(() => {
      if (driver?.id) {
         driver.selectLabel = generateSelectLabelString(driver);
         setDriver(driver);
         setDrivers([driver]);
         setSelectedDriverId(driver?.id);
      }
   }, [driver]);

   const fetchOneDriver = (id) => {
      getDriverById(id).then((res) => {
         if (!res?.json?.data?.id) {
            return;
         }
         const modifiedData = [res.json.data].map((item) => ({
            ...item,
            selectLabel: `${item.firstName} ${item.lastName} ${item.mobileNumber}`
         }));

         setSelectedDriverId(id);
         setDrivers([...modifiedData]);
      });
   };

   const fetchDrivers = (searchParams = {}) => {
      GetDriversListSelect({
         page: 1,
         limit: 100,
         sort: 'status',
         order: 'desc',
         status: ['active', 'inwork'],
         op: 'or',
         ...searchParams
      }).then((res) => {
         const modifiedData = res.data.map((item) => ({
            ...item,
            selectLabel: `${item.firstName} ${item.lastName} ${item.mobileNumber}`
         }));
         setDrivers([...modifiedData]);
      });
   };

   const onFocus = () => {
      fetchDrivers(searchParams);
   };

   const onClear = () => {
      setField(null);
   };

   const onChange = (value, option) => {
      if (value) {
         if (!['active', 'inwork'].includes(option?.info?.status)) {
            const text = {
               pending: 'не активирован',
               inwork: 'в работе',
               blocked: 'заблокирован'
            };

            message.error(
               `Водитель не доступен для выбора, так как он сейчас ${
                  text[option?.info?.status]
               }`
            );
            return;
         }
      }

      let driver = value || null;

      setField(value);
      setDriver(option?.info || {});
      setSelectedDriverId(value);
   };

   const onSearch = (value) => {
      setSearchParams(
         value ? { mobileNumber: value, firstName: value, lastName: value } : {}
      );
   };

   return (
      <Select
         disabled={disabled}
         showSearch
         allowClear
         onClear={onClear}
         placeholder="Введите номер телефона"
         optionFilterProp="children"
         onChange={onChange}
         onFocus={onFocus}
         value={selectedDriverId}
         onSearch={onSearch}
         filterOption={false}
         optionLabelProp="label"
         style={{ width: '100%' }}>
         {drivers.map((item) => (
            <Option
               key={item.id + item.firstName}
               value={item.id}
               label={item.selectLabel}
               info={item}>
               <Badge
                  status={statusesOfDrivers[item.status].badgeStatus}
                  style={{ paddingRight: '8px' }}
               />
               {item.selectLabel}
            </Option>
         ))}
      </Select>
   );
};
export default SelectDriver;
